import Vue from 'vue'
import { HcBoot } from '@hc/hc-boot-vuejs'
import VueNotifications from 'vue-notifications'
import miniToastr from 'mini-toastr'
import moment from 'moment'
import VueScript2 from 'vue-script2'
//import { start, addGlobalUncaughtErrorHandler } from 'qiankun'
import VueMask from 'v-mask'
import 'material-icons/iconfont/material-icons.scss';
import 'typeface-montserrat'

import '@hc/hc-boot-vuejs/scss/main.scss'

export default function ({ app ,$axios }) {

  $axios.$get('public/auth0').then( (dados) => {

    app.$auth.strategies.auth0.options =
      {
        ...app.$auth.strategies.auth0.options,
        client_id: dados.clientId,
        domain: dados.domain,
        audience: dados.audience
      }
  })

  //start()
  //addGlobalUncaughtErrorHandler(event => console.log('error handler', event))

  Vue.use(HcBoot, {
    tabela: {
      exibeExport: false
    }
  })

  Vue.use(VueMask)
  Vue.use(VueScript2)
  Vue.filter('formatDate', function (value) {
    if (value) {
      return moment(String(value)).format(app.i18n.t('DATE_FORMAT'))
    }
  })

  const toastTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn'
  }

  function toast ({ title, message, type, timeout, cb }) {
    return miniToastr[type](message, title, timeout, cb)
  }

  const options = {
    success: toast,
    error: toast,
    info: toast,
    warn: toast
  }

  Vue.use(VueNotifications, options)

  miniToastr.init({ types: toastTypes })

  Vue.prototype.$notify = toast

  app.$notify = toast

  function loadBanner () {
    const po = document.createElement('script'); po.type = 'text/javascript'; po.async = true
    po.src = 'https://front-hub-gsp-multitenancy-prod.s3.amazonaws.com/banner/lgpd-component-fit.min.js'
    po.onload = function () { document.body.append(document.createElement('lgpd-component-fit')) }
    const s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s)
  }
  loadBanner()
}
