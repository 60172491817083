export function SimpleGetters (properties) {
  return properties.reduce((getters, prop) => {
    getters[prop] = function (state) {
      return state[prop]
    }

    return getters
  }, {})
}

export function SimpleMutations (properties) {
  return properties.reduce((mutations, prop) => {
    mutations[prop] = function (state, payload) {
      return state[prop] = payload
    }

    return mutations
  }, {})
}
