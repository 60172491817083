import { deleteItem, getItem, setItem } from './cookies.service'

const primeiroAcessoKey = 'hcentrix-portal-primeiro-acesso'

export function isPrimeiroAcesso (cookie = null) {
  return getItem(primeiroAcessoKey, cookie) == null
}

export function donePrimeiroAcesso () {
  setItem(primeiroAcessoKey, false, { expires: 60 })
}

export function deletePrimeiroAcesso () {
  deleteItem(primeiroAcessoKey)
}
