export default async function ({ isHMR, app, store, route, params, error, redirect }) {

  const defaultLocale = 'pt_Br'
  // If middleware is called from hot module replacement, ignore it
  if (isHMR) { return }
  // Get locale from user

  const usuario = store.getters['session/usuario']

  const locale = (usuario == null ? null : (usuario.additionals == null ? null : usuario.additionals.lang)) || store.state.locale || defaultLocale

  if (!store.state.locales.includes(locale)) {
    // return error({ message: 'This page could not be found.', statusCode: 404 })
  }
  // Set locale
  await store.dispatch('setLang', locale)
  app.i18n.locale = store.state.locale
  // If route is /<defaultLocale>/... -> redirect to /...
  if (locale === defaultLocale && route.fullPath.indexOf('/' + defaultLocale) === 0) {
    const toReplace = '^/' + defaultLocale + (route.fullPath.indexOf('/' + defaultLocale + '/') === 0 ? '/' : '')
    const re = new RegExp(toReplace)
    return redirect(
      route.fullPath.replace(re, '/')
    )
  }
}
