import Vue from 'vue'

Vue.prototype.$loaderShow = showLoader
Vue.prototype.$loaderHide = hideLoader

export default ({ app }, inject) => {
  // Set the function directly on the context.app object
  app.$loaderShow = showLoader
  app.$loaderHide = hideLoader
}

function showLoader () {
  const ret = window.dispatchEvent(new CustomEvent('hc:loader:show'))
}

function hideLoader () {
  window.dispatchEvent(new CustomEvent('hc:loader:hide'))
}
