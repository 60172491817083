<template>
  <div>

    <div v-if="!termoAceito">
      <hc-main :titulo="$t(telaAtual)">
        <b-row >
          <b-col>
            Antes de continuar você precisa aceitar o <a href="https://hc-portal.s3.amazonaws.com/termoUso/Termos+de+Uso_hCentrix_vf_100821.pdf">termo de uso</a> do sistema.

          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <embed src="https://hc-portal.s3.amazonaws.com/termoUso/Termos+de+Uso_hCentrix_vf_100821.pdf" style="height: 80vh; width: 95vw" >
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="primary" @click="aceitarTermo">
                Eu Aceito
              </b-button>
          </b-col>
        </b-row>
      </hc-main>
    </div>
    <div v-else >
    <hc-header
            v-if="usuario"
      :nomeUsuario="usuario.nickname"
      :exibe-acoes="usuario.admin || usuario.adminUsuario"
      linkLogo="/produtos/"
      :versao="versao"
      :dataUltimoAcesso="dataUltimoAcesso"
      :pathLogo="logo"
      :pathFoto="usuario.picture"
      :menuAcoes="acessos"
      @sair="logout"
      @editar-perfil="editarPerfil"
    />

    <hc-main :titulo="$t(telaAtual)">
      <nuxt />
    </hc-main>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {HcHeader, HcMain, ModalGrande} from '@hc/hc-boot-vuejs'
import logoPath from '@/assets/logos/Logo2x.png'
import ModalTextoSimples from "@/components/ModalTextoSimples";
//import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'



export default {
  components: {
    HcHeader, HcMain
    //,VuePdfEmbed
  },
  middleware: 'auth',
  methods: {
    logout () {
      this.$auth.logout().then(() => {
        const a = document.createElement('a')
  		  a.href = `${process.env.VUE_APP_BASE_URL}/login`
        window.location.href = `https://${this.domain}/v2/logout?returnTo=${a.href}`
      })
    },
    async recuperaTermo () {
      const termo = await this.$axios.$get('/api/me/termo')
      if( termo != null ){
        this.termoAceito = termo.termoAceito;
        if( this.termoAceito == null){
          this.termoAceito = false;
        }
      }
    },
    async aceitarTermo(){
      await this.$axios.$post('/api/me/termo/aceito');
      this.recuperaTermo();
    },
    editarPerfil () {
      this.$router.replace({ name: 'meus-dados' })
    }
  },
  data () {
    return {
      termoAceito: true,
      api: process.env.BACK_END_URL,
      domain: this.$auth.strategies.auth0.options.domain,
      logo: logoPath
    }
  },
  computed: {
    ...mapGetters([
      'telaAtual'
    ]),
    usuario () {
      const user = this.$auth.user
      if (user) {
        user.admin = user['https://hcentrix.com/admin']
        user.adminUsuario = user['https://hcentrix.com/adminUsuario']
      }
      return user
    },
    versao () {
      return process.env.APP_VERSION
    },
    dataUltimoAcesso () {
      return new Date(this.usuario.updated_at)
    },
    acessos () {
      const adm = this.usuario.admin
      const admUsuuario = this.usuario.adminUsuario

      const acesso = []
      if (adm === true || admUsuuario === true) {
        acesso.push({ texto: 'Usuário', to: '/adm/usuario' })
        acesso.push({ texto: 'Perfil', to: '/adm/perfil' })
      }
      if (adm === true) {
        acesso.push({ texto: 'Produto', to: '/adm/produto' })
        acesso.push({ texto: 'Empresa', to: '/adm/empresa' })
      }

      return acesso
    }
  },
  mounted () {
    if (this.usuario) {
      this.recuperaTermo();
      if ( window.Conpass) {
        window.Conpass.init({
          name: this.usuario.name,
          email: this.usuario.email
        })
      }
    }

    (function (o, c, t, a, d, e, s, k) {
      o.octadesk = o.octadesk || {};
      o.octadesk.chatOptions = {
        subDomain: a,
        showButton: d,
        openOnMessage: e,
        hide: s
      };
      var bd = c.getElementsByTagName("body")[0];
      var sc = c.createElement("script");
      sc.async = 1;
      sc.src = t;
      bd.appendChild(sc);
    })(window, document, 'https://cdn.octadesk.com/embed.js', 'healthcentrix', 'true', 'true', 'false');
  }

}
</script>
