
import get from 'lodash-es/get'

export default function ({ app, req, $axios, redirect, store, route }) {
  $axios.onRequest(
    (config) => {
      config.url = config.url.replace(/^\/?api/g, '')
      app.$loaderShow()
      if( !config.url.startsWith('/public') && config.url.startsWith('/') ) {
       // console.log("auth", app.$auth.token.get());
       // config.headers.common.Authorization = 'Bearer ' + app.$auth.token.get()
      }
    }
  )

  $axios.onResponse((response) => {
    app.$loaderHide()
    if (!store.getters['session/IGNORAR_QUANDO_NAO_LOGADO'].includes(route.name)) {
      const token = response.headers['x-app-token']
      if (token) {
        store.dispatch('session/setToken', token)
      }
    }
  })

  $axios.onResponseError(() => app.$loaderHide())

  $axios.onError((error) => {
    const status = get(error, 'response.status')

    if (!store.getters['session/IGNORAR_QUANDO_NAO_LOGADO'].includes(route.name)) {
      if (status === 401) {
        store.dispatch('session/logout')
        if (process.browser) {
          app.router.replace({ name: 'login' })
        } else {
          redirect('/')
        }
      }
    }

    if (status >= 500) {
      if (process.browser) {
        app.$notify({
          message: app.i18n.t(`erro.solicitacao`),
          title: app.i18n.t(`title.erro`),
          type: 'error'
        })
      } else {
        redirect('/erro')
      }
    }
  })
}
