<template>
    <b-row>
      <div class="col-sm-12 col-md-12 col-xl-12 bg-primary d-flex align-items-center bg-login" style="height:100vh">
          <form class="form-signin bg-white pt-5 pr-5 pl-5" novalidate="">
                    <div class="text-center mb-4">
                      <img class="mb-4" src="~/assets/logos/Logo2x.png" alt="">
                    </div>
                    <nuxt></nuxt>
                    <small>*Não consegue fazer login? Entre em contato com suporte pelo e-mail <a href="mailto:suporte@hcentrix.com?subject=Problema ao acessar sistema">suporte@hcentrix.com</a></small>
                    <br/><br/>
                    <small>As informações aqui contidas não foram extraídas de sistemas clínicos assistenciais ou laboratoriais, mas de sistemas administrativos e foram geradas por meio de algoritmos, ferramentas estatísticas ou inteligência artificial I.A., o que pode, em alguns casos específicos, não se confirmarem na análise individual.</small>
                    <p class="mt-5 mb-1 text-primary text-center small">{{$t('label.versao')}}: {{versao}} © 2017-2021</p>
                </form>
      </div>
      <div v-if="false" class="col-sm-12 col-md-6 col-xl-5 bg-white d-flex align-items-center" style="height:100vh">
          <hc-rss-reader :feedData="feedData" :default-image="logo" ></hc-rss-reader>
      </div>
    </b-row>
</template>
<style>
img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
    border: none;
}
sub, sup {
    font-size: 61%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
.description {
    padding-top: 8px;
    font-size: 14px;
    max-width: 600px;
}
.title
{
    font-size: 42px;
    font-weight: 300;
    line-height: 1;
}
.account-pages {
    position: absolute;
    background-size: cover;
    background-image: url(/backgrounds/login.jpg);
}

  .no-pading{
    padding-right:0px !important;
  }

  .unauthorized-content {
    background: #fff;
    height: 100%;
    word-break: break-word;
  }
</style>
<script>
import { HcRssReader } from '@hc/hc-boot-vuejs'
import logoPath from '@/assets/banner-v3 1.jpeg'
export default {
  components: { HcRssReader },
  data () {
    return {
      feedData: null,
      versao: process.env.APP_VERSION,
      logo: logoPath
    }
  },
  mounted () {
    // this.$axios.$get('/api/public/rss').then(resp => {
    //   this.feedData = resp
    // })
  }
}
</script>
