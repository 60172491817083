import Cookie from 'js-cookie'
import CookieParser from 'cookieparser'

export function setItem (key, value, options) {
  Cookie.set(key, value, options)
}

export function getItem (key, cookie = null) {
  if (cookie) {
    return CookieParser.parse(cookie)[key]
  }

  return Cookie.get(key)
}

export function deleteItem (key) {
  return Cookie.remove(key)
}
