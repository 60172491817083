export const strict = false


import { SimpleGetters, SimpleMutations } from '@/services/simple-store'
import { getItem, setItem } from '~/services/cookies.service'

const HC_LOCALE = 'hc-locale'

export const state = () => ({
  locales: [],
  locale: getItem(HC_LOCALE) || 'pt_Br',
  loadedLocales: [],
  telaAtual: ''
})

export const actions = {
  nuxtServerInit,
  setLang
}

export const getters = {
  ...SimpleGetters([
    'telaAtual'
  ]
  )
}

export const mutations = {
  ...SimpleMutations([
    'telaAtual'
  ]
  ),
  SET_LANG (state, locale) {
    if (state.locales.includes(locale)) {
      state.locale = locale
    }
  },
  SET_LOCALES (state, locales) {
    state.locales = locales
  },
  ADD_LOADED_LOCALE (state, locale) {
    state.loadedLocales.push(locale)
  }
}

async function setLang ({ commit, state, dispatch }, locale) {
  if (state.locales.length == 0) {
    commit('SET_LOCALES', await this.$axios.$get(`/api/public/locale`))
  }

  if (!state.loadedLocales.includes(locale)) {
    this.app.i18n.setLocaleMessage(locale, await this.$axios.$get(`/api/public/locale/${locale}`))
    this.app.i18n.locale = locale
    commit('ADD_LOADED_LOCALE', locale)
  }
  commit('SET_LANG', locale)
  setItem(HC_LOCALE, locale)
}

function nuxtServerInit ({ commit, getters, dispatch }, { route, req, redirect }) {
}
