import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _16e5a27e = () => interopDefault(import('../pages/meus-dados/index.vue' /* webpackChunkName: "pages/meus-dados/index" */))
const _1a4f1707 = () => interopDefault(import('../pages/produtos/index.vue' /* webpackChunkName: "pages/produtos/index" */))
const _230795a6 = () => interopDefault(import('../pages/adm/empresa/index.vue' /* webpackChunkName: "pages/adm/empresa/index" */))
const _8e064004 = () => interopDefault(import('../pages/adm/perfil/index.vue' /* webpackChunkName: "pages/adm/perfil/index" */))
const _092c369e = () => interopDefault(import('../pages/adm/portal/index.vue' /* webpackChunkName: "pages/adm/portal/index" */))
const _2e09779d = () => interopDefault(import('../pages/adm/produto/index.vue' /* webpackChunkName: "pages/adm/produto/index" */))
const _0f66c4dc = () => interopDefault(import('../pages/adm/usuario/index.vue' /* webpackChunkName: "pages/adm/usuario/index" */))
const _a146223e = () => interopDefault(import('../pages/login/esqueci-senha.vue' /* webpackChunkName: "pages/login/esqueci-senha" */))
const _3d8eed9e = () => interopDefault(import('../pages/login/recuperar-senha.vue' /* webpackChunkName: "pages/login/recuperar-senha" */))
const _1d915356 = () => interopDefault(import('../pages/login/signed-in.vue' /* webpackChunkName: "pages/login/signed-in" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _04b62c47 = () => interopDefault(import('../pages/adm/empresa/edicao/_id.vue' /* webpackChunkName: "pages/adm/empresa/edicao/_id" */))
const _46b49c16 = () => interopDefault(import('../pages/adm/perfil/edicao/_id.vue' /* webpackChunkName: "pages/adm/perfil/edicao/_id" */))
const _577e2052 = () => interopDefault(import('../pages/adm/produto/edicao/_id.vue' /* webpackChunkName: "pages/adm/produto/edicao/_id" */))
const _7d7f89fc = () => interopDefault(import('../pages/adm/usuario/edicao/_id.vue' /* webpackChunkName: "pages/adm/usuario/edicao/_id" */))
const _48f34886 = () => interopDefault(import('../pages/adm/portal/_id.vue' /* webpackChunkName: "pages/adm/portal/_id" */))
const _5a15a1a2 = () => interopDefault(import('../pages/produtos/_id.vue' /* webpackChunkName: "pages/produtos/_id" */))
const _2a923902 = () => interopDefault(import('../pages/produtos/_id/_.vue' /* webpackChunkName: "pages/produtos/_id/_" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/portal/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/meus-dados",
    component: _16e5a27e,
    name: "meus-dados"
  }, {
    path: "/produtos",
    component: _1a4f1707,
    name: "produtos"
  }, {
    path: "/adm/empresa",
    component: _230795a6,
    name: "adm-empresa"
  }, {
    path: "/adm/perfil",
    component: _8e064004,
    name: "adm-perfil"
  }, {
    path: "/adm/portal",
    component: _092c369e,
    name: "adm-portal"
  }, {
    path: "/adm/produto",
    component: _2e09779d,
    name: "adm-produto"
  }, {
    path: "/adm/usuario",
    component: _0f66c4dc,
    name: "adm-usuario"
  }, {
    path: "/login/esqueci-senha",
    component: _a146223e,
    name: "login-esqueci-senha"
  }, {
    path: "/login/recuperar-senha",
    component: _3d8eed9e,
    name: "login-recuperar-senha"
  }, {
    path: "/login/signed-in",
    component: _1d915356,
    name: "login-signed-in"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/adm/empresa/edicao/:id?",
    component: _04b62c47,
    name: "adm-empresa-edicao-id"
  }, {
    path: "/adm/perfil/edicao/:id?",
    component: _46b49c16,
    name: "adm-perfil-edicao-id"
  }, {
    path: "/adm/produto/edicao/:id?",
    component: _577e2052,
    name: "adm-produto-edicao-id"
  }, {
    path: "/adm/usuario/edicao/:id?",
    component: _7d7f89fc,
    name: "adm-usuario-edicao-id"
  }, {
    path: "/adm/portal/:id",
    component: _48f34886,
    name: "adm-portal-id"
  }, {
    path: "/produtos/:id",
    component: _5a15a1a2,
    name: "produtos-id",
    children: [{
      path: "*",
      component: _2a923902,
      name: "produtos-id-all"
    }]
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
