<template>
  <div v-if="show" class="loading-page">
    <div class="lds-dual-ring " />
    <div class="lds-dual-ring-reverse" />
  </div>
</template>

<style scoped>
.loading-page {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding-top: 200px;
  font-size: 30px;
  font-family: sans-serif;
}

.lds-dual-ring {
  display: inline-block;
}
.lds-dual-ring:after {
  position: absolute;
  content: " ";
  display: block;
  margin: 1px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 10px solid #cef;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring-reverse {
  display: inline-block;
}
.lds-dual-ring-reverse:after {
  position: absolute;
  content: " ";
  display: block;
  margin-top: 13px;
  margin-left: 5px;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  border: 10px solid #cef;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring-reverse 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

</style>

<script>
export default {
  name: 'HcLoader',
  props: [],
  data,
  computed: {
    show
  },
  mounted,
  beforeDestroy,
  methods: {
    start,
    finish,
    _show,
    _hide
  }
}

function data () {
  return {
    stack: 0
  }
}

function show () {
  return this.stack > 0
}

function start () {
  this._show()
}

function finish () {
  this._hide()
}

function _show () {
  this.stack += 1
}

function _hide () {
  if (this.stack > 0) {
    this.stack -= 1
  }
}

function mounted () {
  this.registrado = false

  if (!window.HcLoader) {
    window.addEventListener('hc:loader:show', this.start, false)
    window.addEventListener('hc:loader:hide', this.finish, false)
    window.HcLoader = true
    this.registrado = true
  } else {
    this.$destroy()
  }
}

function beforeDestroy () {
  if (this.registrado) {
    window.removeEventListener('hc:loader:show', this.start, false)
    window.removeEventListener('hc:loader:hide', this.finish, false)
  }
}
</script>
