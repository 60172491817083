export const strict = false


import jwt_decode from 'jwt-decode'
import { SimpleGetters, SimpleMutations } from '~/services/simple-store'
import { donePrimeiroAcesso, isPrimeiroAcesso } from '~/services/primeiro-acesso.service'
import { getItem } from '~/services/cookies.service'

const tokenName = 'hc-portal-api-token'

export function state () {
  return {
    token: getItem(tokenName),
    permissoes: null,
    isPrimeiroAcesso: isPrimeiroAcesso() !== false,
    IGNORAR_QUANDO_NAO_LOGADO: ['index', 'login', 'login', 'login-esqueci-senha', 'login-recuperar-senha']
  }
}

export const getters = {
  ...SimpleGetters([
    'token',
    'IGNORAR_QUANDO_NAO_LOGADO',
    'isPrimeiroAcesso'
  ]
  ),
  usuario
}

function usuario (state) {
  try {
    return jwt_decode(state.token)
  } catch {

    return null
  }
}

export const mutations = SimpleMutations([
  'token',
  'permissoes'
])

export const actions = {
  setToken,
  checkToken,
  logout
}

async function checkToken ({ commit, getters, dispatch }) {
  let tokenValido;
  try {
    commit('token', this.$cookies.get(tokenName))
    tokenValido = !!getters.token

    if (tokenValido && !getters.permissoes) {
      const permissoes = await this.$axios.$get('/api/me/permissoes')
      commit('permissoes', permissoes)
    }
  } catch {
    dispatch('logout')
    tokenValido = false
  }
  return tokenValido
}

function setToken (state, token) {
  donePrimeiroAcesso()
  state.commit('token', token)
  this.$cookies.set(tokenName, token)

  const user = jwt_decode(token)
}

function logout ({ commit }) {
  this.$cookies.remove(tokenName)
  commit('token', null)
  commit('permissoes', null)
}
