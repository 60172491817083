<template>
  <div class="confirmacao">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ input.titulo }}
      </h5>
    </div>
    <div class="modal-body">
      {{ input.mensagem }}
    </div>
    <div class="modal-footer">
      <b-button
        @click="$emit('fechar')"
        variant="primary"
        class="btn-sm"
      >
        Fechar
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalTextoSimples',
  props: ['input']
}
</script>
